import * as React from "react"
import type { HeadFC } from "gatsby"
import '../../global-styles/index.scss';
import Header from '../../components/header/header';
import MethodsList from '../../components/methods';
import Footer from '../../components/footer/footer';


const ServicesPage = () => {
  return (
    <main>
      <Header></Header>
      <div className='main-wrapper is-vertical'><h1>Services</h1></div>
      <MethodsList></MethodsList>
      <Footer></Footer>
    </main>
  )
}

export default ServicesPage

export const Head: HeadFC = () => <title>Services Page</title>

import classNames from 'classnames';
import { Link } from 'gatsby';
import * as React from 'react';
import './nav.scss';

const links = [
	{ name: 'Pradinis', url: `/` },
	{ name: 'Apie', url: `/about` },
	{ name: 'Teirautis', url: `/book` },
	{ name: 'Kontaktai', url: `/contacts` }
];

const Nav = (props: any) => {
	const btnClass = classNames({
		'is-active': props.isOpen
	});
	return (
		<nav className={btnClass}>
			<ul>
				{links.map((link) => (
					<li className="nav-link-item" key={link.name}>
						<Link to={link.url} className="nav-link-text" activeClassName="is-active">
							{link.name}
						</Link>
					</li>
				))}
			</ul>
		</nav>
	);
};

export default Nav;

import classNames from 'classnames';
import * as React from 'react';
import '../header.scss';

const HamburgerButton = (props: any) => {
    const btnClass = classNames({
        'hamburger': true,
        'is-active': props.isOpen
      });

    return (
		<button className={btnClass} onClick={() => props.clickFunction(!props.isOpen)} aria-label="toggle navigation menu">
            <span className="line"></span>
            <span className="line"></span>
            <span className="line"></span>
        </button >
	);
};

export default HamburgerButton;

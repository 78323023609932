import * as React from 'react';
import './header.scss';
import HamburgerButton from './hamburger/hamburgerButton';
import Nav from './nav/nav';
import { Link } from 'gatsby';
import mainLogo from '../../assets/icon-128.png';

const Header = () => {
	const [isOpen, setIsOpen] = React.useState(false);

	const toggleOpen = (isOpen: boolean) => {
		setIsOpen(isOpen);
	};

	const [scrollPosition, setScrollPosition] = React.useState(0);
	const handleScroll = () => {
		const position = window.pageYOffset;
		setScrollPosition(position);
	};

	React.useEffect(() => {
		window.addEventListener('scroll', handleScroll, { passive: true });

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<header style={{ 'backgroundColor': scrollPosition > 150 ? 'var(--c-near-white-overlay)' : 'unset' }}>
			<div className="main-wrapper">
				<Link to="/" className="header-logo">
					<img src={mainLogo} alt='mindful girl logo'></img>Simona Grigaraitė
				</Link>
				<Nav isOpen={isOpen}></Nav>
				<HamburgerButton isOpen={isOpen} clickFunction={toggleOpen}></HamburgerButton>
			</div>
		</header>
	);
};

export default Header;
